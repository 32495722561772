import Axios from 'axios';

let access_token = null;

class WebClient {
    
    constructor(defaultConfig) {
        defaultConfig = {
            baseURL: constants.baseURL,
            headers: { 
                "x-api-key": "35678052e01627f4478ff3f442ffb1b6"
            }
        };
        this.axiosInstance = Axios.create(defaultConfig);
    }

    post = (path, data) => {
        let d = data;
        if (access_token !== null) {
            d = {...data, token: access_token}
        }
        var realPromise = this.axiosInstance.post(path, d);
        return this.createProxyPromise(realPromise);
    }

    createProxyPromise(realPromise) {
        var _self = this;
        var proxyPromise = new Promise(function (resolve, reject) {
            realPromise.then(function (data) {
                _self.responseHandler(data, resolve, reject);
            }).catch(error =>{
                _self.errorHandler(error, reject);
            })
        });
        return proxyPromise;
    }
    
    responseHandler=async(response, callback, reject) =>{
        callback(response.data);
    }

    errorHandler = async (error, reject) => {
        reject(error);
    }

}

function setAccessToken(d) {
    access_token = d;
}

function getAccessToken(d) {
    return access_token
}

const messages = {
    required_field: "Bu alanın doldurulması zorunludur",
    invalid_phone: "Geçersiz telefon numarası girdiniz",
    invalid_card: "Geçersiz kart numarası girdiniz",
    invalid_email: "Geçersiz e-posta adresi girdiniz.",
    invalid_min: "Minimum karakter sayısını aşmalısınız.",
    invalid_max: "Maksimum karakter sayısını aştınız.",
    invalid_number: "Lütfen geçerli bir rakam yazınız.",
    invalid_amount: "Lütfen geçerli bir tutar yazınız.",
    invalid_phone_whitespace: "Lütfen telefon numarası içerisinde boşluk karakteri kullanmayın",
    invalid_plate_whitespace: "Lütfen plaka içerisinde boşluk karakteri kullanmayın",
    invalid_card_range: "Kart numarası uygun kart aralıkları içinde değil.",
    invalid_plate: "Lütfen geçerli bir araç plakası yazın",
    invalid_text: "Lütfen geçerli bir değer girin",
    required_select: "Lütfen seçeneklerden birini seçiniz.",
    min_password: "Lütfen şifreyi en az 4 karakter olarak giriniz",
    doesnt_match: "Şifre ve tekrar alanları uyuşmuyor"
}

const constants = {
    // baseURL: "http://localhost:60196/api/cms/",
    // baseURL: "https://4712d6756053.ngrok.io/api/cms/"
    baseURL: "https://oyun.kumbaradergisi.com/panel/public/api/",
}

function gh(p) {
    let a = (p / 1080) * 100;
    return `${a}vh`;
}

function gw(p) {
    let a = (p / 1920) * 100;
    return `${a}vw`;
}

export {
    gh,
    gw,
    WebClient,
    setAccessToken,
    messages,
    constants,
    getAccessToken
}