import React from 'react'
import "./styles/index.css"
import "./styles/main.scss"

class MobileHelper extends React.Component {
  render() {
    return (
      <div className="mobile-helper">
        OYUNU OYNAYABİLMEK İÇİN LÜTFEN TELEFONUNUZU YAN ÇEVİRİN.
      </div>
    )
  }
}

export default MobileHelper
