import { combineReducers, createStore } from "redux"
import { slateblue } from "color-name";
import { setAccessToken, WebClient } from '../utils/webclient'
import sound_click from "../../assets/sounds/click.wav"
import sound_correct from "../../assets/sounds/correct.wav"
import sound_login from "../../assets/sounds/login.wav"
import sound_wrong from "../../assets/sounds/wrong.wav"
import { Pages, Messages } from './__utils.ts';
import moment from "moment"
import "moment/locale/tr"
moment.locale("tr");

const audio_click = new Audio(sound_click);
const audio_correct = new Audio(sound_correct);
const audio_wrong = new Audio(sound_wrong);                
const audio_login = new Audio(sound_login);    

let interval = null;

let timer_counter = 0;

let userState = {
    is_logged: false,
    data: {
        tutorial1: "0",
        tutorial2: "0"
    }
}

const member = (state = userState, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                is_logged: true,
                data: action.payload
            }
        case "LOGOUT":
            return {
                is_logged: false,
                data: null
            }
        case "SET_MEMBER_DATA":
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

let tutorialState = {
    first_tier: false,
    second_tier: false
}

const tutorial = (state = tutorialState, action) => {
    switch (action.type) {
        case "SET_FIRST_TIER":
            return {
                ...state,
                first_tier: action.payload
            }
        case "SET_SECOND_TIER":
            return {
                ...state,
                second_tier: action.payload
            }
        default:
            return state;
    }
}

let badge_state = {
    badges: [],
    point: 0
}

const badge = (state = badge_state, action) => {
    switch (action.type) {
        case "SET_BADGES":
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

let tooltip_state = {
    show: false,
    type: 0
}

const tooltip = (state = tooltip_state, action) => {
    switch (action.type) {
        case "SET_TOOLTIP":
            return {
                show: action.show,
                type: action.tooltype
            }
        default:
            return state;
    }
}

let modal_state = {
    show: false,
    message: null
}

const modal = (state = modal_state, action) => {
    switch (action.type) {
        case "SET_MODAL":
            return {
                show: action.show,
                message: action.message
            }
        default:
            return state;
    }
}

let right_message_state = {
    show: false,
    message: null,
    back: null
}

const right_message = (state = right_message_state, action) => {
    switch (action.type) {
        case "SET_RIGHT_MESSAGE":
            return {
                show: action.show,
                message: action.message,
                back: action.back
            }
        default:
            return state;
    }
}

let work_state = {
    is_working: false,
    status: 0
}

const work = (state = work_state, action) => {
    switch (action.type) {
        case "SET_WORK_STATE":
            return {
                ...action.payload
            }
        default:
            return state;
    }
}

const reducer = combineReducers({
    member,
    tutorial,
    badge,
    tooltip,
    modal,
    right_message,
    work
})

const store = createStore(reducer);


function login(data, history, remember = false, udata = null) {
    store.dispatch({
        type: "LOGIN",
        payload: data
    });
    setAccessToken(data.token);
    if (remember) {
        localStorage.setItem("user_data", JSON.stringify(udata));
    }
    history.push("/")
}

function setMemberData(data) {
    store.dispatch({
        type: "SET_MEMBER_DATA",
        payload: data
    });
}

function setTooltip(show, tooltype) {
    store.dispatch({
        type: "SET_TOOLTIP",
        show,
        tooltype
    });
}

function setModal(show, message) {
    store.dispatch({
        type: "SET_MODAL",
        show,
        message
    });
}

function setRightMessage(show, message, back) {
    store.dispatch({
        type: "SET_RIGHT_MESSAGE",
        show,
        message,
        back
    });
}

async function logout() {
    setAccessToken(null);
    localStorage.clear();
    store.dispatch({
        type: "LOGOUT"
    });
}

async function setTutorialDone() {
    store.dispatch({
        type: "SET_DONE"
    });
}

function setFirstTier(data) {
    store.dispatch({
        type: "SET_FIRST_TIER",
        payload: data
    });
}

function setBadges(data) {
    store.dispatch({
        type: "SET_BADGES",
        payload: data
    });
}

function setSecondTier(data) {
    store.dispatch({
        type: "SET_SECOND_TIER",
        payload: data
    });
}

function setWorkStatus(is_working, status) {
    store.dispatch({
        type: "SET_WORK_STATE",
        payload: {
            is_working,
            status
        }
    });
}

function startWorkTimer() {
    interval = setInterval(() => {
        if (timer_counter == 100) {
            clearInterval(interval);
            timer_counter = 0;
            stopWorking();
        } else {
            timer_counter = timer_counter + 1;
            setWorkStatus(true, timer_counter);
        }
    }, 300);
}

function startWorking() {

    let ws = new WebClient();
    ws.post("users/work-start", {})
        .then((response) => {
            // console.log(response)
            if (response.status) {
                
                let a = moment(new Date()).format('x');
                
                let b = {
                    working: true,
                    start: a
                }
                
                localStorage.setItem("work_status", JSON.stringify(b));
                startWorkTimer();
                setWorkStatus(true, 0);

            } else {
                setRightMessage(true, Messages.CalismaniTamamladin, Pages.Anasayfa)
            }
        })
}

function stopWorking() {

    let ws = new WebClient();
    ws.post("users/work-end", {})
        .then((response) => {
            // console.log(response)
            if (response.status) {
                
                let b = {
                    working: false,
                    start: 0
                }
                
                localStorage.setItem("work_status", JSON.stringify(b));
                setWorkStatus(false, 0);
                setRightMessage(true, Messages.CokCalistin, Pages.Anasayfa)

            }
        })
}


function playSound(type = "click") {
    if (type == "click") {
        audio_click.play();
    } else if (type == "wrong") {
        audio_wrong.play();
    } else if (type == "login") {    
        audio_login.play();
    } else if (type == "correct") {
        audio_correct.play();       
    }
}

export {
    store,
    logout,
    login,
    setMemberData,
    setTutorialDone,
    setFirstTier,
    setSecondTier,
    setBadges,
    setTooltip,
    playSound,
    setModal,
    setRightMessage,
    setWorkStatus,
    startWorking
}