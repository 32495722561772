import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import { store } from './components/redux'
import Spinner from './components/spinner'
import * as serviceWorker from "./serviceWorker";
import "normalize.css";
import "./styles/global.css";
import "./styles/index.css";
import MobileHelper from './mobile-helper'

import ReactGA from 'react-ga';

const trackingId = "UA-92796091-8";
ReactGA.initialize(trackingId);

const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
      <MobileHelper />
    </Suspense>
  </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
