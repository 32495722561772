export enum Pages {
    YasamTercihleri,
    YasamTercihleri2,
    Anasayfa,
    CalismayaBasla,
    Market,
    Esyalarim,
    SosyalSorumluluk,
    GonulluSosyalSorumluluk,
    BagisliSosyalSorumluluk,
    EglenceMarketi,
    OyuncakMarketi,
    TeknolojiMarketi,
    YiyecekMarketi,
    Hediyeler,
    Olaylar,
    Arkadaslar,
    Profil,
    Done,
    ProfilBilgileri,
    Message
}

export enum Messages {
    Hosgeldin,
    CokCalistin,
    CalismaniTamamladin,
    CaniniTazele,
    MarketHakkiniDoldurdun,
    BagisHakkiniDoldurdun,
    HediyeHakkiniDoldurdun
}